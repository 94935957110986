<template>
  <div class="customer-oa-detail">
    <van-search
        v-model="customerValue"
        disabled
        placeholder="请输入搜索关键词"
    />
    <div :style="{height: freshHeight+ 20 +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            loading-text="加载中"
            :finished="finished"
            finished-text="没有更多了"
            @load="nextPage"
        >
          <van-cell v-for="item in list" :key="item.id" :title="item.cusName" value="同步升级"
                    is-link to="index">
            <!-- 使用 title 插槽来自定义标题 -->
            <template slot="title">
              <van-row>
                <el-tooltip class="item" effect="dark" :content="item.cusName" placement="bottom">
                  <van-col offset="0" span="24" class="text van-ellipsis info">
                    {{ item.cusName }}
                  </van-col>
                </el-tooltip>
              </van-row>
            </template>
            <template slot="label">
              <van-row>
                <van-col offset="0" span="20" class="text van-ellipsis info"
                         :style="{ textAlign: 'center', fontSize: '15px'}">
                  匹配相似度：<span :style="{color: 'red'}">{{ item.ssd }}</span>
                </van-col>
              </van-row>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
import {getSimilarCustomerOaInfo} from '@/api/customerOa'

export default {
  name: "CustomerOaDetail",
  data() {
    return {
      list: [],
      customerValue: '',
      loading: false,
      finished: false,
      refreshing: false,
      freshHeight: 0,
      currentPage: 1, // 当前页码
      pageSize: 15, // 每页个数
    };
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.customerValue = this.$route.params.name
    this.onRefresh()
  },

  methods: {
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push('鞍钢股份有限公司');
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 下拉刷新
    getData() {
      this.loading = true
      getSimilarCustomerOaInfo({
        current: this.currentPage,
        size: this.pageSize,
        cusName: this.customerValue
      }).then(res => {
        this.loading = false
        // 如果下拉刷新，那么数据清空重新获取第一页
        if (this.refreshing) {
          this.list = []
          this.refreshing = false
        }
        console.log(res)
        res.forEach(item => {
          this.list.push(item)
        })
        this.listRow = this.list.length
        // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
        if (res.length < this.pageSize - 1) {
          this.finished = true
        } else {
          this.finished = false
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    }
  }
}
</script>

<style scoped>
.customer-oa-detail .custom-title {
  text-align: center;
  vertical-align: center;
}
</style>